import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Img from "gatsby-image"
const slugify = require("slugify")
import NewsletterForm from "../components/newsletter-form"

export default class Podcast extends React.Component<any, any> {
  render() {
    const episodes = this.props.data.allMdx.nodes.filter(item => item.frontmatter.published)

    return (
      <Layout>
        <SEO
          title="Podcast"
          description="Le podcast Supravore : interviews d'experts et de passionnés sur les domaines de la santé, l'environnement et les performances humaines."
        />
        <Header />
        <main className="content blog-list">
          <section className="content__row content__row--bleed static-header__header">
            <h1 className="static-header__title content__row">Podcast</h1>
            <div className="content__row " style={{ paddingBottom: 0 }}>
              <p>
                Des interviews d'experts et de passionnés sur les domaines de la santé,
                l'environnement et les performances humaines.
              </p>
            </div>
          </section>
          <section className="content__row content__row--overlap podcast">
            <h2 className="content__row-title">{/*Épisodes*/}</h2>
            <ul className="product-grid product-grid">
              {episodes.map(this.renderCardForPublished)}
            </ul>
            {this.renderUpcoming()}
            <NewsletterForm />
          </section>
        </main>
      </Layout>
    )
  }

  renderUpcoming() {
    const episodes = this.props.data.allMdx.nodes
      .filter(item => !item.frontmatter.published)
      .reverse()
    return (
      <>
        <section className="content__row content__row--bleed static-header__header podcast-upcoming">
          <h2 className="static-header__title content__row">Prochainement...</h2>
          <div className="content__row " style={{ paddingBottom: 0 }}>
            <p style={episodes.length == 0 ? {} : { display: "none" }}>
              De nouveaux épisodes arriveront très bientôt...
            </p>
          </div>
          <br />
        </section>
        <section className="content__row content__row--overlap podcast">
          <ul className="product-grid product-grid">{episodes.map(this.renderCardForUpcoming)}</ul>
        </section>
      </>
    )
  }

  renderCardForPublished = (item, index) => {
    const { title, summary, featuredImage } = item.frontmatter
    const href = "/podcast/" + slugify(title.toLowerCase(), { lower: true, strict: true })
    return (
      <li key={index} className="product-grid__item blog-card">
        <Link to={href}>
          <figure className="js-figure-link product-grid__item-figure js-figure-link">
            <picture>
              <Img
                fluid={featuredImage.childImageSharp.fluid}
                alt={title}
                className="product-grid__item-image product-grid__item-image--featured"
              />
            </picture>
          </figure>
        </Link>
        <div className="product-grid__definition">
          <h3 className="product-grid__title">
            <Link to={href}>{title}</Link>
          </h3>
          {/* <span className="product-grid__category">{summary}</span> */}
        </div>
      </li>
    )
  }

  renderCardForUpcoming = (item, index) => {
    const { title, summary, featuredImage } = item.frontmatter
    return (
      <li key={index} className="product-grid__item blog-card">
        <figure className="js-figure-link product-grid__item-figure js-figure-link">
          <picture>
            <Img
              fluid={featuredImage.childImageSharp.fluid}
              alt={title}
              className="product-grid__item-image product-grid__item-image--featured"
              style={{ cursor: "default" }}
            />
          </picture>
        </figure>
        <div className="product-grid__definition">
          <h3 className="product-grid__title">{title}</h3>
          {/* <span className="product-grid__category">{summary}</span> */}
        </div>
      </li>
    )
  }
}

export const pageQuery = graphql`
  query PodcastEpisodeList {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/podcast/" } }
    ) {
      nodes {
        frontmatter {
          title
          summary
          published
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
